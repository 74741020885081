/* ==============================================
== How Work Section Css Start
============================================== */
.tool-work-section {
  .tool-work-box-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .tool-work-box {
      max-width: 31.7%;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
      padding: 30px;

      .tool-box-head-content {
        h4 {
          color: #2d2d2f;
          text-align: center;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 24px;
        }
      }

      .tool-box-icon-wrap {
        border-radius: 10px;
        border: 1px dashed rgba(0, 118, 206, 0.3);
        background: rgba(200, 231, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 30px;

        .tool-box-icon-info {
          img {
            width: 100%;
            max-width: 68px;
          }
        }

        .tool-box-icon-info.tool-box-icon-text {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          gap: 6px;

          span {
            color: #808080;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
        }
      }

      .tool-box-button {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-radius: 34px;
          background: #0076ce;
          border: 1px solid #0076ce;
          width: 100%;
          padding: 13px;
          margin-top: 23px;
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}

.convert-info-wrap {
  .tool-about-box-wrap {
    padding-bottom: 0px;
  }
}

.tool-work-section.blue-design-pattern {
  position: relative;
  z-index: 1;
  margin-top: 150px;
  padding-bottom: 225px;

  &:before {
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: 100%;
    top: 0;
    bottom: -1px;
    left: 10px;
    right: 10px;
    background-color: #eff7fd;
    z-index: -1;
    border-radius: 20px;
    background-image: url('../../../../assets/images/home/line-design-pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 1326px) {
  .tool-work-section {
    .tool-work-box-wrap {
      .tool-work-box {
        max-width: 48%;

        .tool-box-head-content {
          h4 {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tool-work-section.blue-design-pattern:before {
    height: calc(100% - 30px);
  }

  .tool-work-section.blue-design-pattern {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .tool-work-section.blue-design-pattern {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 991px) {
  .tool-work-section {
    .tool-work-box-wrap {
      gap: 20px;

      .tool-work-box {
        max-width: 39%;

        .tool-box-head-content {
          h4 {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tool-work-section {
    .tool-work-box-wrap {
      .tool-work-box {
        max-width: 100%;

        .tool-box-head-content {
          h4 {
            font-size: 16px;
          }
        }

        .tool-box-icon-wrap {
          .tool-box-icon-info {
            img {
            }
          }
        }

        .tool-box-button {
          button {
            font-size: 15px;
            padding: 10px;
          }
        }
      }
    }
  }

  .tool-work-section.blue-design-pattern {
    margin-top: 0px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 575px) {
  .tool-work-section {
    .tool-work-box-wrap {
      .tool-work-box {
        padding: 25px;

        .tool-box-head-content {
          h4 {
          }
        }

        .tool-box-icon-wrap {
          gap: 20px;
          padding: 20px;

          .tool-box-icon-info {
            img {
              max-width: 50px;
              width: 100%;
            }
          }
        }

        .tool-box-button {
          button {
            font-size: 15px;
            padding: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
}

/* ==============================================
== How Work Section Css End
============================================== */
