.mobile-menu-toggle-list.dashboard-header-menu {
  max-width: 415px;
  width: 100%;

  li.ant-dropdown-menu-item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(135, 135, 135, 0.2);
      border-radius: 0px;
      padding: 0px 20px;
    }

    &:last-child {
      padding: 0px 20px 15px 20px;
    }
    &:first-child {
      padding: 10px 20px 0px 20px;
    }
  }

  span.ant-dropdown-menu-title-content {
    padding: 20px 0px 15px 0px;
  }

  ul.ant-dropdown-menu.ant-dropdown-menu-root {
    padding: 0px;
  }

  .mobile-menu-text {
    a {
      color: #878787;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      padding-right: 13px;
      margin-right: 22px;
      position: relative;
    }
  }
}
.dashboard-header-menu-icon {
  font-size: 30px;
}

@media screen and (max-width: 474px) {
  .mobile-menu-toggle-list.dashboard-header-menu {
    max-width: 263px;
    width: 100%;
  }
  span.ant-dropdown-menu-title-content {
    padding: 15px 0px 10px 0px;
  }
}
