$color_1: #0076ce;
$color_2: #181d20;
$color_3: #393946;
$color_4: #00acea;
$color_5: #fff;
$color_6: #fff;
$color_7: #707375;
$color_8: #007bff;
$color_9: #2d2d2f;
$color_10: #808080;
$font-family_1: Inter;
$font-family_2: Roboto;
$background-color_1: #eff7fd;
$background-color_2: rgba(0, 172, 234, 0.1);
$background-color_3: var(--toolBodyClr);
$background-color_4: var(--elementClr);
$background-color_5: #fff;

.blue-design-pattern {
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: 100%;
    top: 0;
    bottom: -1px;
    left: 10px;
    right: 10px;
    background-color: $background-color_1;
    z-index: -1;
    border-radius: 20px;
    background-image: url('../../assets/images/home/line-design-pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// common title css fro homepage
.common-title {
  span {
    color: $color_1;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    display: inline-block;
  }

  h2 {
    color: $color_2;
    font-size: 44px;
    font-weight: 900;
    line-height: 1.2;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: $color_10;
    line-height: 1.5;
  }
}

.pt-100 {
  padding-top: 100px;
}

.banner-bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.slick-dots {
  li {
    button {
      padding: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s;

      &:before {
        font-size: 0;
        width: 8px;
        height: 8px;
        background: rgba(255, 106, 72, 0.3);
        border-radius: 10px;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }

  li.slick-active {
    button {
      &:before {
        background: rgba(255, 106, 72, 0.7);
        width: 100%;
        height: 100%;
      }
    }
  }
}

.file-box-content {
  h3 {
    color: $color_9;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  p {
    color: $color_10;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
}

@media screen and (max-width: 1200px) {
  .pt-100 {
    padding-top: 70px;
  }

  .common-title {
    h2 {
      font-size: 38px;
    }
  }

  .toll-box-inner {
    width: 33.33%;
  }
}

@media only screen and (max-width: 1200px) {
  .common-title {
    h2 {
      font-size: 38px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .how-work-section.blue-design-pattern {
    &:before {
      height: calc(100% - 70px);
    }
  }
}

@media screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }

  .how-work-section {
    padding-bottom: 50px;
  }

  .blue-design-pattern {
    &:before {
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  .common-title {
    span {
      font-size: 14px;
    }

    h2 {
      font-size: 32px;
    }
  }

  .banner-bg-img {
    display: none;
  }

  .tool-about-box {
    max-width: 80%;
    margin: 0;
  }

  .tool-about-box-wrap {
    padding: 50px 0;
    margin: 0;
  }

  .file-box-content {
    h3 {
      margin-bottom: 10px;
    }
  }

  .toll-box-inner {
    width: 100%;
  }

  .toll-box-inner {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .common-title {
    span {
      font-size: 14px;
      font-size: 14px;
    }

    h2 {
      font-size: 32px;
      br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .file-box-content {
    h3 {
      font-size: 20px;
    }
  }
}
