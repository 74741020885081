$color_1: #393946;
$color_2: #808080;
$color_3: #fff;
$color_4: #0076ce;

/*=============================================
= Unsubscribe section css
=============================================*/
/*.unsubscribe-section .text-content {
    margin-bottom: 50px;
}*/
/*=============================================
= Unsubscribe section css End
=============================================*/
.unsubscribe-section {
  text-align: center;
  margin: 20px 10px 10px;
  border-radius: 30px;
  background: #eff7fd;
  width: auto;
  padding: 135px 0px;
  .text-content {
    .title {
      color: $color_1;
      font-size: 42px;
      font-weight: 900;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .description {
      color: $color_2;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }
    input {
      margin-top: 20px;
      border-radius: 50px;
      border: 1px solid #d8d9d9;
      background: #fff;
      padding: 15px 13px 15px 20px;
      width: 100%;
      font-size: 16px;
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
      position: relative;
      &::placeholder {
        opacity: 0.4;
      }
    }
    .submit-btn {
      margin-top: 20px;
      position: absolute;
      top: 5px;
      right: 4px;
      button {
        border-radius: 42px;
        background: #0076ce;
        border: 1px solid #0076ce !important;
        display: inline-flex;
        padding: 9px 54px;
        justify-content: center;
        align-items: center;
        color: $color_3;
        border: none;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
          background: transparent;
          color: $color_4;
        }
        svg {
          width: 21px;
          height: 21px;
          margin-right: 5px;
        }
      }
      &:hover {
        svg {
          g {
            path {
              fill: #0076ce;
            }
          }
        }
      }
      &:focus {
        svg {
          g {
            path {
              fill: #0076ce;
            }
          }
        }
      }
    }
  }
}
.unsubscribe-item-control-input-content {
  display: inline-block;
  width: 100%;
  max-width: 536px;
  position: relative;
  input#email {
    &:focus-within {
      outline: none;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    }
  }
}
@media screen and (max-width: 625px) {
  .unsubscribe-section {
    .text-content {
      .submit-btn {
        position: unset;
        button {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .unsubscribe-section {
    padding: 50px 0px;
    .text-content {
      .title {
        font-size: 32px;
      }
      .description {
        font-size: 16px;
        margin-top: 15px;
      }
      input {
        font-size: 16px;
        margin-top: 15px;
      }
      .submit-btn {
        button {
          font-size: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .unsubscribe-section {
    margin-bottom: 50px;
  }
  .unsubscribe-item-control-input-content {
    max-width: 415px;
  }
  .text-content {
    margin: 0px 20px;
  }
  .unsubscribe-section {
    .text-content {
      input {
        padding: 13px 13px 13px 20px;
      }
      .submit-btn {
        button {
          padding: 10px 54px;
        }
      }
    }
  }
}
