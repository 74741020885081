@font-face {
  font-family: 'GT Walsheim Pro';
  src:
    local('GT Walsheim Pro Regular'),
    local('GT-Walsheim-Pro-Regular'),
    url('../assets/font/GTWalsheimPro-Regular.woff2') format('woff2'),
    url('../assets/font/GTWalsheimPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0px 0px;
  padding: 0px 0px;
}

ul {
  list-style: none;
  padding: 0px 0px;
  margin: 0px 0px;
}

a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.4s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Inter', sans-serif;
}

p,
span,
li {
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px 0px;
  padding: 0px 0px;
}

body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
  display: inline-block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
}
* {
  box-sizing: border-box;
}
dl,
ol,
ul,
a {
  list-style: none;
  text-decoration: none;
  margin: 0px 0px;
  padding: 0px 0px;
}
strong {
  font-weight: bolder;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
i {
  font-style: italic;
}
div {
  outline: none;
}
img {
  max-width: 100%;
  height: auto;
}
/*=============================================
= Section Defult
=============================================*/

/*=============================================
= Container Css Start
=============================================*/
.main_container {
  margin: 0px auto;
  width: 100%;
  max-width: 1290px;
  padding: 0px 15px;
}
@media screen and (max-width: 575px) {
  .main_container {
    max-width: 100%;
    padding: 0px 30px;
  }
}

/*=============================================
= Animation Css Start
=============================================*/

.navigation-links {
  .link-list {
    li {
      a {
        &:before {
          content: '';
          position: absolute;
          top: auto;
          bottom: -5px;
          right: auto;
          left: 0px;
          width: 0%;
          height: 2px;
          background-color: #0076ce;
          transition: all 0.5s ease-in-out;
        }
        &:hover {
          &:before {
            width: 80%;
          }
        }
      }
    }
  }
}
.banner-section {
  .banner-content {
    margin-bottom: 50px;
  }
}
.drag-drop-inner {
  transition: all 0.3s ease-out;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid rgba(0, 118, 206, 0.2);
    box-shadow:
      0 2.8px 2.2px rgba(0, 118, 206, 0.034),
      0 6.7px 5.3px rgba(0, 118, 206, 0.048),
      0 12.5px 10px rgba(0, 118, 206, 0.06),
      0 22.3px 17.9px rgba(0, 118, 206, 0.072),
      0 41.8px 33.4px rgba(0, 118, 206, 0.086),
      0 0px 80px rgba(0, 0, 0, 0.12);
  }
  .drag-drop-element {
    margin-top: 0px;
  }
}
.banner-device-element {
  .device-icon {
    .device-icon-inner {
      position: relative;
      z-index: 9;
      background-color: transparent;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-out;
        background-color: #eff7fd;
        display: inline-block;
        vertical-align: top;
        z-index: -1;
      }
      &:hover {
        &:before {
          width: 0%;
          background-color: #eff7fd;
        }
      }
    }
  }
}

// Animation New Css

.banner-device-element {
  .device-icon {
    .device-icon-inner {
      &:hover {
        span {
          transform: scale(1.2);
        }
        img {
          transform: scale(1.1);
        }
      }
      img {
        transition: all 0.3s ease-out;
        max-width: 25px;
        height: 100%;
      }
    }
  }
}

.banner-bg-img {
  .animation_wrapper {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    animation: actionicon 1s infinite alternate;
  }
  .animation_banner_img1 {
    text-align: left;
  }
  .animation_banner_img4 {
    position: absolute;
    top: 15%;
    bottom: auto;
    right: 5%;
    left: auto;
    text-align: right;
  }
}

@keyframes actionicon {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.pricing-box-info {
  .pricing-box-inner {
    width: 100%;
    align-items: center;
    .pricing-content {
      p {
        max-width: 100%;
      }
      .pricing-content-icon {
        margin-top: 5px;
      }
    }
  }
}
.tool-inner-slider {
  .toll-box-inner {
    .tool-box {
      .box-upper {
        transition: all 0.4s ease-in-out;
        .box-head {
          .number-tool {
            transition: all 0.4s ease-in-out;
          }
        }
        .box-body {
          .box-content {
            img {
              transition: all 0.4s ease-in-out;
            }
          }
        }
        &:hover {
          .box-body {
            .box-content {
              img {
                transform: scale(1.6);
              }
            }
          }
          .box-head {
            .number-tool {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .toll-box_wrap1 {
    .tool-box {
      .box-upper {
        &:hover {
          background-color: rgb(111 86 236 / 85%);
        }
      }
    }
  }
  .toll-box_wrap2 {
    .tool-box {
      .box-upper {
        &:hover {
          background-color: rgb(246 98 19 / 85%);
        }
      }
    }
  }
  .toll-box_wrap3 {
    .tool-box {
      .box-upper {
        &:hover {
          background-color: rgb(28 103 202 / 85%);
        }
      }
    }
  }
  .toll-box_wrap4 {
    .tool-box {
      .box-upper {
        &:hover {
          background-color: rgb(36 120 129 / 85%);
        }
      }
    }
  }
}
