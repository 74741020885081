.privacyPolicy-main-section {
  .back-grad-title {
    background-image: linear-gradient(90deg, #0076ce, #0076ce);
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    margin-bottom: 40px;
    padding: 100px 20px 80px;
  }

  .back-grad-title {
    .pricing-main-title {
      color: #fff;
      font-size: 65px;
      text-align: center;
      font-family: 'GT Walsheim Pro';
    }
  }

  .main_container {
    width: calc(100% - 40px);
    padding: 0px;

    .dashboard-box {
      box-shadow: 0 5px 100px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      border-radius: 1.6rem;
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 30px;
      margin-bottom: 30px;

      @media only screen and (max-width: 767px) {
        padding-left: 22px;
        padding-right: 22px;
      }

      .card {
        padding: 0 30px 30px;

        @media only screen and (max-width: 767px) {
          padding: 0 0 30px;
        }

        .docs-section:first-child {
          border-top: none;
          padding-top: 0;
        }

        .docs-section {
          padding-top: 1.5rem;
          margin-top: 1.5rem;
          border-top: 1px solid #f1f1f5;

          h5 {
            font-weight: 500;
            line-height: 1.4;
            color: #000;
            font-size: 30px;
            margin: 0;
            margin-bottom: 15px;
            word-break: break-all;
            font-family: 'GT Walsheim Pro';
          }

          p {
            margin: 0;
            color: #868686;
            line-height: 1.4;
            font-size: 15px;
            font-family: 'GT Walsheim Pro';
          }

          a {
            color: #868686;
            text-decoration: none;
            font-family: 'GT Walsheim Pro';
          }

          ul {
            list-style: outside;
            margin: 16px 0px;
            padding: 0px 0px 0px 40px;

            li {
              margin: 0;
              color: #868686;
              line-height: 1.4;
              font-size: 15px;
              font-family: 'GT Walsheim Pro';
            }
          }
          span {
            font-family: 'GT Walsheim Pro';
          }

          .mt-1 {
            margin: 10px 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .privacyPolicy-main-section {
    .back-grad-title {
      padding-bottom: 60px;
      padding-top: 70px;
    }

    .back-grad-title {
      .pricing-main-title {
        word-wrap: break-word;
        font-size: 40px;
      }
    }
  }
}
