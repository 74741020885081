.account_bg_wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 92px;
  margin-bottom: 30px;
}

.account_title {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  h2 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px solid rgba(217, 217, 217, 0.7);
    margin: 0px 0px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    color: #000;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.wpb_content_account {
  .account_info_cmt {
    display: flex;
  }
  .account_info_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .wi-50 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  .account_address {
    strong {
      width: 100%;
      font-family: Roboto;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.1;
      color: #444444;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
      a {
        width: 100%;
        font-family: Roboto;
        display: flex;
        align-items: center;
        column-gap: 150px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #596171;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .account_title {
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
  .wpb_content_account {
    .account_address {
      p {
        a {
          column-gap: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_bg_wrap {
    padding: 15px 15px;
  }
  .account_title {
    h2 {
      font-size: 20px;
    }
  }
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: nowrap;
      justify-content: space-between;
      .wi-50 {
        max-width: max-content;
        flex: 0 0 max-content;
      }
    }
    .account_address {
      strong {
        width: auto;
      }
      p {
        width: auto;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: wrap;
      row-gap: 20px;
      .wi-50 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .account_address {
        &:first-child {
          border-bottom: 1px solid rgb(119 119 119 / 10%);
          padding-bottom: 20px;
        }
      }
    }
  }
}

// Billing Information Css

.account-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;

  .field-account.address {
    grid-column: 1 / 3;
  }

  label.lbl_wpb_wrapper {
    width: 100%;
    font-family: Roboto;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    color: #777777;
    margin-bottom: 10px;
  }

  .field-account {
    input[type='text'],
    input[type='number'],
    select {
      width: 100%;
      border-radius: 5px;
      font-family: Roboto;
      border: 1px solid #dfeaee;
      background: rgba(236, 247, 251, 0.5);
      padding: 16px 13px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      outline: none;
      color: #9ea8bd;
      -webkit-appearance: none;

      &::placeholder {
        color: #9ea8bd;
      }
    }

    input[type='number'] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px 0px;
      }
    }
  }
}

button.payment__account {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 9px 15px;
  color: #ffffff;
  background-color: #0076ce;
  border: 1px solid #0076ce;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: transparent;
    border: 1px solid #0076ce;
    color: #0076ce;
  }
}

@media only screen and (max-width: 625px) {
  .account-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
    .field-account.address {
      grid-column: 1 / 1;
    }
    .field-account {
      input[type='text'],
      input[type='number'] {
        padding: 13px 13px;
        font-size: 14px;
      }
    }
  }
}

// Subscriptions Css
.wpb_info_wrapper {
  padding: 29px 0px 20px;
}
.subscriptions_info_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #444;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  button {
    color: #24537b;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: unset;
  }
}
@media only screen and (max-width: 475px) {
  .subscriptions_info_wrap {
    padding: 20px 25px;
    border-radius: 5px;
    border: 1px dashed #24537b;
    background: #fff;
  }
}
@media only screen and (max-width: 425px) {
  .subscriptions_info_wrap {
    padding: 15px 5px;
    border-radius: 5px;
    border: 1px dashed #24537b;
    background: #fff;
    span {
      font-size: 13px;
    }
    button {
      font-size: 13px;
    }
  }
}
