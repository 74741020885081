/* ==============================================
== Perfect Tool Css start
============================================== */
.Perfect-tool {
  /*     width: calc(100% - 20px);*/
  padding: 150px 0px;
}

// .common-title span {
//     color: #0076CE;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     margin-bottom: 10px;
//     display: inline-block;
// }
// .Perfect-tool .common-title h2 {
//     font-size: 44px;
//     line-height: 1.2;
// }
// .common-title p {
//     font-size: 16px;
//     font-weight: 400;
//     color: #808080;
// }
// .Perfect-tool .common-title p{
//     margin-top: 20px;
// }
.Perfect-tool .Perfect-tool-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 20px;
  gap: 30px;

  @media only screen and (max-width: 475px) {
    gap: 0;
  }
}

.Perfect-tool .right-content {
  padding-right: 40px;
}

.Perfect-tool .right-content,
.Perfect-tool .left-content {
  width: 50%;
}

.Perfect-tool .Perfect-tool-info .mobile-view-img {
  display: none;
}

.Perfect-tool .Perfect-tool-info .right-content {
  border-radius: 20px;
  background: #eff7fd;
  padding: 41px 35px;
}

.Perfect-tool .Perfect-tool-info .right-content .display-image-view {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .Perfect-tool {
    padding: 120px 0px;
  }
}

@media only screen and (max-width: 1200px) {
  // .common-title h2{
  //     font-size: 42px;
  // }
  .Perfect-tool {
    padding: 100px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .Perfect-tool .Perfect-tool-info {
    margin: 0px;
    flex-direction: column;
  }

  .Perfect-tool .right-content,
  .Perfect-tool .left-content {
    width: 100%;
  }

  .Perfect-tool .Perfect-tool-info .right-content {
    padding: 0px;
    margin-bottom: 30px;
  }

  .Perfect-tool {
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 767px) {
  // .common-title span{
  //     font-size: 14px;
  // }
  // .Perfect-tool .common-title h2{
  //     font-size: 32px;
  // }
  .Perfect-tool-info {
    flex-direction: column !important;
  }

  .Perfect-tool .Perfect-tool-info .mobile-view-img {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }

  .Perfect-tool .Perfect-tool-info .right-content {
    display: none;
  }

  .Perfect-tool {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 676px) {
  .Perfect-tool {
    margin: 0px 0px 0px;
    width: auto;
  }
}

@media only screen and (max-width: 550px) {
  .Perfect-tool .Perfect-tool-info .mobile-view-img {
    width: 100%;
    display: block;
  }
}

/* ==============================================
    == Perfect Tool Css End
    ============================================== */

/* ==============================================
== Modern work Css start
============================================== */
.modern-work {
  border-radius: 30px;
  background: #eff7fd;
  margin: 0px 10px 10px;
  position: relative;
  width: calc(100% - 20px);
  padding: 40px 0px;
}

.modern-work::before {
  content: '';
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-image: url('../../../../assets/images/home/modern-file-convert-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// .modern-work .common-title p{
//    margin: 30px 0 15px;
// }
.modern-work .modern-work-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 20px;
}

.modern-work .modern-work-info .mobile-view-img {
  display: none;
}

.modern-work .modern-work-info .right-content {
  border-radius: 20px;
  background: #eff7fd;
  padding: 41px 35px;
}

.modern-work .modern-work-info .right-content .display-image-view {
  display: block;
  width: 100%;
}

.modern-work .modern-work-info .left-content .upload-btn {
  margin-top: 15px;
  border-radius: 42px;
  background: #0076ce;
  border: 1px solid #0076ce !important;
  padding: 9px 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

.modern-work .modern-work-info .left-content .upload-btn:hover {
  background-color: #fff !important;
  color: #0076ce !important;
  border: 1px solid #0076ce !important;
}

// @media screen and (max-width: 1200px){
//    .common-title h2{
//        font-size: 44px;
//    }
// }
@media only screen and (max-width: 900px) {
  .modern-work .modern-work-info {
    margin: 0px;
    flex-direction: column-reverse;
  }

  .modern-work .modern-work-info .right-content {
    padding: 0px;
  }
}

@media only screen and (max-width: 767px) {
  //    .common-title span{
  //        font-size: 14px;
  //    }
  //    .common-title h2{
  //        font-size: 32px;
  //    }
  .modern-work-info {
    flex-direction: column !important;
  }

  //    .modern-work .common-title p{
  //      margin: 20px 0 15px;
  //    }
  //    .modern-work .common-title h2 br{
  //        display: none;
  //    }
  .modern-work .modern-work-info .mobile-view-img {
    width: 60%;
    display: block;
  }

  .modern-work .modern-work-info .right-content {
    display: none;
  }
}

@media only screen and (max-width: 676px) {
  .modern-work {
    margin: 0px 0px 10px;
    width: auto;
  }
}

@media only screen and (max-width: 550px) {
  .modern-work .modern-work-info .mobile-view-img {
    width: 100%;
    display: block;
    padding-bottom: 30px;
  }
}

// @media only screen and (max-width:375px){
//        .modern-work .common-title p {
//        margin: 10px 0 10px;
//    }
// }

/* ==============================================
== Modern work Css End
============================================== */
