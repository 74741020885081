@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
$color_1: #393946;
$color_2: #0076ce;
$color_3: #00acea;
$font-family_1: 'Inter', sans-serif;
$font-family_2: Roboto;
$background-color_1: rgba(0, 172, 234, 0.1);

.banner-bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-section {
  padding: 100px 0px 100px;
}

.banner-content {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;

  h1 {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 52px;
    font-weight: 900;
    margin-bottom: 25px;
  }

  p {
    color: $color_1;
    font-family: $font-family_2;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
  }
}

.drag-drop-inner {
  border-radius: 10px;
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.083),
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  height: 100%;
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
}

.drag-drop-element {
  input[type='file'] {
    display: none;
  }

  max-width: 1010px;
  width: 100%;
  height: 280px;
  border-radius: 10px;
  background: #fff;
  margin: 50px auto 0;
  position: relative;

  label {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 255px;
    justify-content: center;
    flex-direction: column;
    color: #808080;
    text-align: center;
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    @media only screen and (max-width: 360px) {
      font-size: 14px;
    }

    &:before {
      content: '';
      width: 50px;
      height: 50px;
      background-image: url('../../../../assets/images/home/cloud-upload-icon.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: 45px;
      margin-bottom: 20px;
    }

    &:after {
      content: 'Browse File';
      padding: 10px 50px;
      margin-top: 20px;
      border-radius: 42px;
      border: 1px dashed rgba(0, 118, 206, 0.4);
      background: #0076ce;
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    inset: 15px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 118, 206, 0.4);
    background: rgba(217, 217, 217, 0.01);
  }
}

.banner-device-element {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 15px 44px;
  gap: 15px;

  @media only screen and (max-width: 475px) {
    padding: 0 15px 30px;
    gap: 11px;
  }

  .device-icon {
    width: 25%;
    padding-right: 0px;

    .device-icon-inner {
      height: 60px;
      border-radius: 5px;
      border: 1px solid #eaf0fb;
      background: #eff7fd;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @media only screen and (max-width: 475px) {
        .device-icon-inner img {
          width: 100%;
          max-width: 26px;
        }
      }
    }
  }

  span {
    color: #6b6b6b;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: all 0.3s ease-out;
    @media only screen and (max-width: 850px) {
      font-size: 13px;
    }
  }
}

.file-convert-list-main {
  padding: 0 15px;

  .your-file span {
    color: #9aa2a9;
    font-family: $font-family_1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .single_line {
    border-bottom: 1px solid rgba(0, 118, 206, 0.16);
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .file-converting-main {
    display: flex !important;
    column-gap: 20px;
    align-items: center;
    justify-content: start;

    .file-list-inner {
      p {
        color: #393946;
        font-family: $font-family_1;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 3px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      span {
        color: #a7afb5;
        font-family: $font-family_1;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .convert-formate-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 118, 206, 0.16);
    margin-bottom: 15px;
    position: relative;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 15px;
    }

    .convert-formate {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .convert-to-main {
      display: flex;
      align-items: center;
      column-gap: 31px;

      .convert-close img {
        cursor: pointer;
        width: 12px;
        min-width: 12px;

        @media only screen and (max-width: 767px) {
          position: absolute;
          top: 50px;
          right: 7px;
          transform: translate(50%, -50%);
          bottom: 50%;
        }
      }

      .convert-option div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .convert-option div {
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid rgba(0, 118, 206, 0.25);
      background: #fff;
      width: 100%;
      min-width: 129px;
      padding: 8px 10px;
      outline: navajowhite;
      color: #393946;
      appearance: none;
      font-family: $font-family_1;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .convert-formate span {
      color: #0076ce;
      font-family: $font-family_1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .convert-img img {
      width: 18px;
      min-width: 18px;
      height: 18px;
      display: block;
    }
  }

  .convert-btn button {
    border-radius: 45px;
    border: 1px solid rgba(0, 118, 206, 0.2);
    background: #0076ce;
    padding: 10px 40px;
    color: #fff;
    font-family: $font-family_1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    text-transform: capitalize;
  }

  .convert-btn {
    padding-top: 15px;
    padding-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      padding-top: 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .banner-bg-img {
    display: none;
  }

  .banner-section {
    background-image: url('../../../../assets/images/home/mobile-hero-banner-pattern.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 70px 0px 60px;

    @media only screen and (max-width: 475px) {
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .banner-content {
    h1 {
      font-size: 36px;
    }

    p {
      font-size: 16px;
    }
  }

  .banner-device-element {
    span {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .banner-device-element {
    .device-icon {
      &:not(:first-child) {
        padding-left: 0px;
      }

      &:not(:last-child) {
        padding-right: 0px;
      }

      .device-icon-inner {
        height: 48px;
      }
    }

    .drag-drop-element {
      margin-top: 40px;

      label {
        height: 280px;
        font-size: 14px;

        &:before {
          margin-top: 15px;
          margin-bottom: 20px;
        }

        &:after {
          font-size: 14px;
        }
      }
    }
  }
}

.custom-select-popup {
  position: relative;
}

.popup {
  position: absolute;
  top: -30px;
  right: -40%;
  transform: translate(-50%, 0);
  background: white;
  border: 1px solid rgba(0, 118, 206, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 449px;
  padding: 10px 15px 18px 15px;
  z-index: 1000;
  border-radius: 10px;

  @media only screen and (min-width: 767.5px) and (max-width: 1440px) {
    top: -22px;
    right: 0px !important;
    transform: unset;
  }

  @media only screen and (max-width: 767px) {
    transform: translate(0, 0);
    position: fixed;
    inset: 0;
    width: 100%;
    max-width: 100% !important;
    border: 0;
    border-radius: 0;
    overflow: scroll;
  }

  .close-btn {
    text-align: right;
    padding-bottom: 14px;
    display: none;

    @media only screen and (max-width: 767.5px) {
      display: block;
    }
  }

  .field-item.selected span {
    color: #0076ce;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .field-item {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 118, 206, 0.2);
      padding-bottom: 13px;
      margin-bottom: 10px;
      gap: 6px;
    }
  }

  .field-item span {
    color: #393946;
    font-size: 14px;
    font-weight: 500;
  }

  .field-item.selected {
    border-bottom: 1px solid #0076ce;
    padding-bottom: 13px;
    margin-bottom: 10px;
  }

  .popup-row input {
    border: 0;
    color: rgba(57, 57, 70, 0.7);
    padding-left: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;

    .ant-input-search .ant-input:focus {
      border: 0 !important;
      border-radius: 0;
      box-shadow: unset;
    }
  }
}

.popup-row {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 118, 206, 0.2);
    margin-bottom: 10px;
  }

  .ant-btn-default {
    border: 0;
    justify-content: right;
    box-shadow: unset;
  }

  .ant-input-search-button:hover {
    color: unset !important;
  }
}

.search-input {
  width: 100%;
  padding: 5px;
}

.columns {
  display: flex;
  gap: 12px;
  align-items: start;
  justify-content: start;
}

.column {
  &.second-column ul {
    row-gap: 10px !important;
    column-gap: 7px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      color: #393946;
      text-align: center;
      font-family: $font-family_1;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 7px 0 !important;
      border-radius: 2px !important;
      width: 100%;
      min-width: 90px;
      max-width: fit-content;
      padding: 5px 0;
      border: 1px solid rgba(0, 118, 206, 0.3);

      @media only screen and (max-width: 767px) {
        min-width: 100%;
        word-wrap: break-word;
      }
    }
  }
}

.first-column {
  width: 100%;
  min-width: 123px;
  max-width: fit-content;

  @media only screen and (max-width: 475px) {
    min-width: 0;
    width: unset;
  }
}

.second-column {
  width: 75%;
  padding-left: 0;

  @media only screen and (max-width: 410px) {
    width: 60%;
  }
}

// ****** drop-box-move-section *******

.drop-box-move-section {
  .drag-drop-element {
    background-color: transparent;
    height: 440px;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .drop-box-move-inner {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .box-move-head-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 45px;
        margin-bottom: 44px;

        .convert-box-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          img {
            max-width: 30px;
            width: 100%;
            height: 100%;
          }

          span {
            color: #000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .drop-box-convert-icon {
        img {
          max-width: 249.2px;
          width: 100%;
        }
      }

      .drop-box-convert-content {
        margin-top: 20px;

        h3 {
          color: #393946;
          font-family: Inter;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          margin-bottom: 10px;
        }

        p {
          color: #393946;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .drop-box-progress-bar {
          margin-top: 20px;

          .progress-bar-inner {
            .ant-progress-outer {
              @media only screen and (max-width: 768px) {
                width: 80% !important;
              }
            }

            .ant-progress-inner {
              background-color: #dbf0ff;

              .ant-progress-bg.ant-progress-bg-outer {
                background-color: #0076ce;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .box-move-head-content {
          margin-bottom: 30px;
        }

        .drop-box-convert-icon {
          img {
            max-width: 230px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .box-move-head-content {
          margin-bottom: 20px;
        }

        .drop-box-convert-icon {
          img {
            max-width: 200px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 26px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .drag-drop-element {
      .drop-box-move-inner {
        .box-move-head-content {
          gap: 30px;
        }

        .drop-box-convert-icon {
          img {
            max-width: 150px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 18px;
          }

          p {
            padding: 0px 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 475px) {
    .drag-drop-element {
      height: 400px;

      .drop-box-move-inner {
        .box-move-head-content {
          gap: 30px;
        }

        .drop-box-convert-icon {
          img {
            max-width: 150px;
          }
        }

        .drop-box-convert-content {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// Animation Css

.banner-device-element {
  .device-icon {
    .device-icon-inner {
      &:hover {
        span {
          transform: scale(1.2);
        }
        img {
          transform: scale(1.1);
        }
      }
      img {
        transition: all 0.3s ease-out;
        max-width: 25px;
        height: 100%;
      }
    }
  }
}

.banner-bg-img {
  .animation_wrapper {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    animation: actionicon 1s infinite alternate;
  }
  .animation_banner_img1 {
    text-align: left;
  }
  .animation_banner_img4 {
    position: absolute;
    top: 15%;
    bottom: auto;
    right: 5%;
    left: auto;
    text-align: right;
  }
}

@keyframes actionicon {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
