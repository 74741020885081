$color_1: #393946;
$color_2: #0076ce;
$color_3: #878787;
$color_4: #ffffff;
$color_5: #1b5f92;
$color_6: #808080;
$color_7: #000000;
$color_8: #fff;
$font-family_1: Inter;
$font-family_2: Roboto;
$background-color_1: transparent;

section.pricing_banner_wrap {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -100px;
    bottom: auto;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    height: calc(100% + 100px);
    border-radius: 0px 0px 30px 30px;
    background: #eff7fd;
    z-index: -1;
  }
}

.inside_pricing__content {
  h1 {
    font-size: 52px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;
    color: $color_1;
    text-align: center;
  }
}

.site__pricing_banner {
  .common-title {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 50px;

    span {
      color: $color_2;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
      display: inline-block;
    }

    h2 {
      color: $color_1;
      font-size: 42px;
      font-weight: 900;
      text-transform: capitalize;
    }
  }

  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: auto;
    left: 0px;
    right: auto;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    max-height: 741px;
    background-image: url('../../assets/images/pricing/pricing_bg_pattern.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

.site_pr_padding {
  padding: 130px 0px;
}

.pricing-box-inner {
  display: flex;
  align-items: start;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 48px;
  }
}

.pricing-icon {
  border-radius: 6px;
  background: #e9f5ff;
  padding: 6.67px;
}

.pricing-content {
  .pricing-content-icon {
    img {
      padding-right: 10px;
    }
  }

  p {
    color: $color_3;
    font-family: $font-family_1;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 461px;
    margin-left: 0;
  }
}

.pricing_content {
  display: flex;

  .pricingCompareBox {
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    box-shadow: 0px 0px 25px 5px rgba(184, 185, 206, 0.25);
  }

  .pricing-box-1 {
    .pricingCompareBox {
      padding: 40px 40px;
    }
  }

  .pricing-box-2 {
    .pricingCompareBox {
      padding: 30px 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
    }
  }

  .pricing-box-3 {
    .pricingCompareBox {
      padding: 30px 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
    }
  }

  .pricing-content-icon {
    display: flex;
    align-items: start;
  }

  .pricing-content-icon img:last-child {
    padding: 0;
  }
}

.pricingCompareBox {
  .pricingComparePlan {
    a {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 5px 15px;
      color: $color_4;
      border-radius: 10px;
      border: 1px solid #2a2a33;
      background: #2a2a33;
      margin-bottom: 15px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
  }

  .pricingComparePrice {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 44px;

    h3 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $color_5;
      margin-bottom: 15px;

      small {
        font-size: 14px;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $color_6;
    }
  }

  .pricingCompareBtn {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    a {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color_4;
      border-radius: 40px;
      background: #1b5f92;
      border: 1px solid #1b5f92;
      padding: 7.5px 0 !important;
      text-align: center;
      text-transform: capitalize;
      transition: all 0.4s ease-in-out;
      max-width: 250px;

      &:hover {
        color: $color_5;
        background-color: $background-color_1;
        border: 1px solid #1b5f92;
      }
    }
  }

  .pricingCompareText {
    span {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 20px;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      color: $color_7;
      margin-bottom: 20px;
    }

    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 20px;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      color: $color_7;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 400;
      color: $color_3;
    }
  }
}

.pricingEmailBox {
  input {
    width: 100%;
    border: 1px solid #eeeeee;
    padding: 20px 15px;
    border-radius: 10px;
    outline: none;
    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 25%);
  }
}

.pricing-box-1 {
  width: 50%;
  margin-right: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.pricing-box-2 {
  width: 25%;
  margin-right: 30px;
}

.pricing-box-3 {
  width: 25%;
}

p.sub-text {
  font-size: 14px;
  font-weight: 400;
  color: $color_8;
}

.subscribe-box {
  align-items: center;
  background: #1b5f92;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-left: 15px;

  .btn {
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 23px;
    margin: 2px;
    border: 1px solid #fff;
  }
}

@media screen and (max-width: 1325px) {
  section.pricing_banner_wrap {
    &:before {
      left: 10px;
      right: 10px;
      width: calc(100% - 20px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .inside_pricing__content {
    h1 {
      font-size: 44px;
    }
  }

  .site_pr_padding {
    padding: 90px 0px;
  }
}

@media screen and (max-width: 767px) {
  .inside_pricing__content {
    h1 {
      font-size: 36px;
    }
  }

  .site__pricing_banner {
    .common-title {
      span {
        font-size: 14px;
      }

      h2 {
        font-size: 26px;
      }
    }
  }

  .site_pr_padding {
    padding: 70px 0px;
  }

  .pricingCompareBox {
    .pricingCompareBtn {
      a {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .site__pricing_banner {
    .common-title {
      h2 {
        font-size: 34px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .pricing_content {
    flex-wrap: wrap;
  }

  .pricing-box-1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .pricing-box-3 {
    width: calc(50% - 15px);
  }

  .pricing-box-2 {
    width: calc(50% - 15px);
  }
}

@media only screen and (max-width: 767px) {
  .pricing-box-3 {
    width: 100%;
  }

  .pricing-box-2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-box-3 {
    width: 100%;
  }

  .pricing-box-2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
  }

  .pricing_content {
    .pricing-box-1 {
      .pricingCompareBox {
        padding: 20px 20px;
      }
    }

    .pricing-box-2 {
      .pricingCompareBox {
        padding: 20px 20px;
      }
    }

    .pricing-box-3 {
      .pricingCompareBox {
        padding: 20px 20px;
      }
    }
  }

  .pricing-box-inner {
    .pricing-content {
      p {
        font-size: 16px;
      }
    }

    .pricing-icon {
      width: 41px;
    }

    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }

  .pricing-box-1 {
    margin-bottom: 48px;
  }

  .pricing-content {
    width: 85%;
  }

  .pricingCompareBox {
    .pricingComparePrice {
      margin-bottom: 20px;
    }
  }

  .common-title {
    h2 {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 525px) {
  section.pricing_banner_wrap {
    &:before {
      left: 0px;
      right: 0px;
      width: 100%;
    }
  }

  .site_pr_padding {
    padding: 50px 0px;
  }

  .site__pricing_banner {
    .common-title {
      margin-bottom: 32px;
    }
  }
}
