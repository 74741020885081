$color_1: #878787;
$color_2: #0076ce;
$color_3: #fff;
$font-family_1: Roboto;
$background-color_1: #878787;
$background-color_2: #0076ce;
$background-color_3: #393946;
$background-color_4: #fff;

/* ==============================================
== Header Css Start
============================================== */
/* ==============================================
== Header Css End
============================================== */
header.site-header {
  position: relative;
  z-index: 9;
  padding: 30px 0px;
}

.Header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.header-logo {
  img {
    display: block;
    width: 100%;
    max-width: 290px;
    height: 100%;
  }
}

.navigation-links {
  .link-list {
    display: flex;
    align-items: center;
    column-gap: 22px;

    li {
      a {
        color: $color_1;
        font-family: $font-family_1;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        padding-right: 13px;
        position: relative;

        &:active {
          color: $color_2;
        }

        &:hover {
          color: $color_2;

          &:after {
            background-color: $background-color_2;
          }
        }

        &:after {
          position: absolute;
          content: '';
          width: 5px;
          aspect-ratio: 1;
          border-radius: 50%;
          right: 0;
          bottom: 0;
          background-color: $background-color_1;
          opacity: 0.3;
        }
      }

      a.active {
        &:after {
          background-color: $background-color_2;
        }
      }

      &:last-child {
        a {
          margin-right: 30px;
        }
      }
    }
  }
}

.navigation-menu {
  display: flex;
  align-items: center;
  position: relative;

  .header-lang-drop {
    li {
      list-style: none;
      margin-right: 40px;
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-menu-icon {
    display: none;
    transition: all 0.3s ease 0s;
  }
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 35px;
  border-radius: 50px;
  background: #393946;
  font-family: $font-family_1;
  font-weight: 500;
  line-height: 1.3;

  a {
    color: $color_3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a:hover {
    color: $color_3;
  }

  img {
    margin-right: 8px;
  }

  span {
    font-size: 16px;
    color: $color_3;
  }
}

.humburger-icon {
  span {
    width: 14px;
    height: 2px;
    border-radius: 2px;
    background-color: $background-color_4;
    display: block;
    margin: 2px 0px;
  }
}

.mobile-menu-icon.active {
  .humburger-icon {
    span.stick_2 {
      display: none;
    }

    span.stick_1 {
      transform: rotate(45deg) translate(2px, 0.5px);
    }

    span.stick_3 {
      transform: rotate(-45deg) translate(2px, -0.5px);
    }
  }
}

.mobile-menu-toggle-list {
  max-width: 415px;
  width: 100%;

  li.ant-dropdown-menu-item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(135, 135, 135, 0.2);
      border-radius: 0px;
      padding: 0px 20px;
    }

    &:last-child {
      padding: 0px 20px 15px 20px;
    }

    &:first-child {
      padding: 10px 20px 0px 20px;
    }
  }

  span.ant-dropdown-menu-title-content {
    padding: 20px 0px 15px 0px;
  }

  ul.ant-dropdown-menu.ant-dropdown-menu-root {
    padding: 0px;
  }

  .mobile-menu-text {
    color: #878787;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    padding-right: 13px;
    margin-right: 22px;
    position: relative;
  }
}

// *****************language-popup-start

.header-language-modal {
  .ant-modal-content {
    padding: 48px 10px 5px 10px;
    min-height: 640px;
    height: 100%;

    .ant-modal-header {
      .ant-modal-title {
        font-size: 28px;
        text-align: center;
        margin: 0 0 25px;
      }
    }
  }

  .ant-modal-body {
    word-wrap: break-word;
    line-height: 1.5714285714;
    margin-top: 0;
    max-height: 700px;
    overflow: overlay;

    .df-modal-language {
      height: 100%;
      max-height: 510px;
      overflow-x: visible;
      overflow-y: scroll;
      width: 100%;

      .lang {
        align-items: center;
        border-color: currentcolor #f1f1f5 #f1f1f5;
        border-top: 0.5px solid #f1f1f5;
        cursor: pointer;
        display: flex;
        gap: 10px;
        justify-content: center;
        padding: 10px 0;
        text-align: center;

        .flag {
          img {
            border-radius: 100%;
            object-fit: cover;
          }
        }

        span {
          color: #000;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}

// *****************language-popup-end

@media screen and (max-width: 1199px) {
  header.site-header {
    padding: 24px 0px;
  }

  .mobile-menu-icon {
    color: #000000;
    display: flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }

  .navigation-links {
    position: absolute;
    width: 180px;
    padding: 12px;
    background-color: $background-color_4;
    border: 1px solid #878787;
    top: 40px;
    right: 0px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;

    ul {
      flex-direction: column;

      li {
        width: 100%;

        a {
          width: 100%;
          margin: 4px 0px;
        }
      }
    }
  }

  .navigation-menu {
    .mobile-menu-icon {
      display: block;
    }
  }

  .navigation-links.active {
    opacity: 1;
    visibility: visible;
    right: 0;
  }

  .login-btn {
    display: none;
    opacity: 0;
  }

  .login-btn-show {
    display: block;
    opacity: 1;
  }

  .navigation-menu {
    .header-lang-drop {
      li {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  header.site-header {
    padding: 20px 0px;
  }

  .login-btn {
    img {
      width: 16px;
    }
  }

  .header-logo {
    img {
      max-width: 160px;
    }
  }

  .navigation-menu {
    .header-lang-drop {
      li {
        margin-right: 10px;
      }
    }
  }

  .header-language-modal {
    .ant-modal-content {
      padding: 35px 10px 5px 10px;
      min-height: 550px;

      .ant-modal-header {
        .ant-modal-title {
          font-size: 24px;
        }
      }
    }

    .ant-modal-body {
      .df-modal-language {
        .lang {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 474px) {
  .mobile-menu-toggle-list {
    max-width: 263px;
    width: 100%;

    span.ant-dropdown-menu-title-content {
      padding: 15px 0px 10px 0px;
    }
  }
}
