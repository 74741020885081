/* ==============================================
== How Work Section Css Start
============================================== */

.how-work-box-wrap {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
  margin-top: 65px;
}

.how-work-box {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 30px;
  margin: 0 15px;
  max-width: calc(33.33% - 30px);
  width: 100%;
  background-image: url('../../../../assets/images/home/background-layer.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 475px) {
    padding: 30px;
  }

  .file-box-number {
    display: flex;
    align-items: inherit;
    justify-content: right;

    h4 {
      color: #0076ce;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 900;
      line-height: 70px;
      text-transform: capitalize;
      opacity: 0.5;
      margin-bottom: 30px;
    }
  }
}

.file-box-icon {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.file-box-icon img:not(:last-child) {
  margin-right: 10px;
}

.file-box-content {
  h3 {
    color: #2d2d2f;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1200px) {
  .how-work-section.blue-design-pattern:before {
    height: calc(100% - 70px);
  }

  .how-work-box:nth-child(1) {
    height: 530px;
  }

  .how-work-box:nth-child(2) {
    height: 477px;
  }

  .how-work-box:nth-child(3) {
    height: 418px;
  }
}

@media screen and (max-width: 1200px) {
  .how-work-box {
    .file-box-number {
      h4 {
        font-size: 60px;
        line-height: 50px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .how-work-box {
    max-width: calc(40% - 30px);
  }

  .how-work-box:not(:last-child) {
    margin-bottom: 30px;
  }

  .how-work-section {
    padding-bottom: 70px;
  }

  .how-work-box-wrap {
    align-items: unset;
  }
}

@media screen and (max-width: 991px) {
  .how-work-box {
    max-width: calc(50% - 30px);
  }

  .how-work-box-wrap {
    margin-top: 48px;
  }
}

@media screen and (max-width: 767px) {
  .how-work-box {
    max-width: 100%;
    margin: 0;
  }

  .file-box-icon img {
    max-width: 90px;
    width: 100%;
    object-fit: cover;
  }

  .file-box-icon {
    margin-bottom: 30px;
  }

  .file-box-content h3 {
    margin-bottom: 10px;
  }

  .file-box-icon img:not(:last-child) {
    margin-right: 6px;
  }

  .how-work-box-wrap {
    margin-top: 40px;
  }
}

@media screen and (max-width: 575px) {
  .how-work-box {
    max-width: 100%;

    .file-box-number {
      display: none;
    }
  }
}

@media screen and (max-width: 440px) {
  .how-work-box {
    padding: 20px;
  }

  .file-box-icon img {
    max-width: 80px;
  }

  .file-box-content h3 {
    font-size: 20px;
  }
}

/* ==============================================
== How Work Section Css End
============================================== */
